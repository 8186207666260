import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';

import {
  DOC_TYPE_CONSENT_ORDERS,
  DOC_TYPE_PROPERTY_AGREEMENT,
  PROPERTY_DASHBOARD_COMPLETED,
  ASSET_SPLIT_SELF_PARTY_COMPLETING,
  DOCUMENT_TYPES_OTHER_NOT_READY,
  DOCUMENT_TYPES_WAITING_FOR_OTHER,
  RESET_SUGGESTED_DIVISION_WAITING_FOR_SELF,
  RESET_SUGGESTED_DIVISION_WAITING_FOR_OTHER,
  RESET_SUGGESTED_DIVISION_OTHER_NOT_READY,
} from 'Common/constants';

import getNames from 'Common/Utils/getNames';
import { resetAgreementStateAction } from 'App/State/MatterActions';
import MatterProps from 'Common/Utils/MatterProps';
import { ODRS_DIVISION_ASSETS_INFO_INTRO } from 'Common/routes';
import AssetSplitAgreed from 'Common/UI/Banners/PropertyDashboardCompleted';
import DocumentTypesOtherNotReady from 'Common/UI/Banners/DocumentTypesOtherNotReady';
import DocumentTypesWaitingForOther from 'Common/UI/Banners/DocumentTypesWaitingForOther';
import AssetSplitSelfOfferInProgress from 'Common/UI/Banners/AssetSplitSelfOfferInProgress';
import {
  ResetSuggestedDivisionWaitingForSelf,
  ResetSuggestedDivisionWaitingForOther,
  ResetSuggestedDivisionOtherNotReady,
} from 'Common/UI/Banners/ResetSuggestedDivision';

const HomeBannerContent = ({
  navigate,
  appState,
  resetPropertyAgreementState,
  resetConsentOrdersAgreementState,
  page,
}) => {
  const { other: otherName } = getNames();

  const navigateWithAssetSplitIntroGuard = route => {
    if (MatterProps('self.hasSeenAssetTransferIntro', false)) {
      navigate(route);
    } else {
      navigate(ODRS_DIVISION_ASSETS_INFO_INTRO, { nextRoute: route });
    }
  };

  switch (appState) {
    case RESET_SUGGESTED_DIVISION_WAITING_FOR_SELF:
      return <ResetSuggestedDivisionWaitingForSelf otherName={otherName} />;
    case RESET_SUGGESTED_DIVISION_WAITING_FOR_OTHER:
      return <ResetSuggestedDivisionWaitingForOther otherName={otherName} />;
    case RESET_SUGGESTED_DIVISION_OTHER_NOT_READY:
      return <ResetSuggestedDivisionOtherNotReady otherName={otherName} />;
    case DOCUMENT_TYPES_WAITING_FOR_OTHER:
      return <DocumentTypesWaitingForOther otherName={otherName} />;
    case DOCUMENT_TYPES_OTHER_NOT_READY:
      return (
        <DocumentTypesOtherNotReady
          otherName={otherName}
          resetPropertyAgreementState={resetPropertyAgreementState}
          resetConsentOrdersAgreementState={resetConsentOrdersAgreementState}
        />
      );
    case PROPERTY_DASHBOARD_COMPLETED:
      return <AssetSplitAgreed page={page} navigate={navigate} />;
    case ASSET_SPLIT_SELF_PARTY_COMPLETING:
      return (
        <AssetSplitSelfOfferInProgress
          navigateWithAssetSplitIntroGuard={navigateWithAssetSplitIntroGuard}
          otherName={otherName}
        />
      );
    default:
      return null;
  }
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
  resetPropertyAgreementState: () =>
    dispatch(resetAgreementStateAction({ name: DOC_TYPE_PROPERTY_AGREEMENT })),
  resetConsentOrdersAgreementState: () =>
    dispatch(resetAgreementStateAction({ name: DOC_TYPE_CONSENT_ORDERS })),
});

HomeBannerContent.propTypes = {
  navigate: PropTypes.func.isRequired,
  resetPropertyAgreementState: PropTypes.func.isRequired,
  resetConsentOrdersAgreementState: PropTypes.func.isRequired,
  appState: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeBannerContent);
