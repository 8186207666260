import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import PageContainer from 'Common/UI/Layout/PageContainer';
import { sessionStorageLoad } from 'Common/Utils/sessionStorage';
import {
  ODRS_PAYMENT_REVIEW_ORDER_ROUTE,
  ODRS_PAYMENT_COMPLETE_ROUTE,
} from 'Common/routes';
import push from 'Common/Utils/push';
import { getTotalPrice, getItemsAwaitingPayment } from 'Common/Utils/Payments';
import formatCurrency from 'Common/Utils/formatCurrency';

import {
  requestPaymentAction,
  requestPaymentTokenAction,
} from 'App/State/MatterActions';
import baseTheme from 'Common/Utils/theme';
import Loader from 'Common/UI/Loader/Loader';
import { Heading2 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import PaymentHeader from './PaymentHeader';
import { PaymentProvider } from './PaymentContext';
import { PaymentDrawer, DrawerContent, PaymentFooter } from './common';
import CybersourcePaymentForm from './CybersourcePaymentForm';

const PaymentFailed = styled(Paragraph)`
  background: ${props => props.theme.colours.lightOrange};
  padding: ${props => props.theme.padding.small}px;
  border-radius: 4px;
`;

const PaymentPayForm = ({
  navigate,
  matter,
  requestPayment,
  requestPaymentToken,
}) => {
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [captureContext, setCaptureContext] = useState(null);
  const [fatalError, setFatalError] = useState(false);

  const itemsAwaitingPayment = getItemsAwaitingPayment(matter);

  const selected = sessionStorageLoad(
    'PaymentSelectedAgreementTypes'
  ).filter(sel => itemsAwaitingPayment.includes(sel));

  // on load, setup the forms capture context
  useEffect(() => {
    if (!selected || !Array.isArray(selected) || selected.length === 0) {
      navigate(ODRS_PAYMENT_REVIEW_ORDER_ROUTE);
    }

    async function getRequestToken() {
      const responseToken = await requestPaymentToken();
      setCaptureContext(responseToken);
    }

    getRequestToken();
  }, []);

  const amount = getTotalPrice(matter, selected);

  const handlePayment = token => {
    setIsPaymentInProgress(true);

    requestPayment({
      token,
      skus: selected,
    })
      .then(response => {
        navigate(ODRS_PAYMENT_COMPLETE_ROUTE, { skus: selected });
      })
      .catch(error => {
        console.error('Error when attempting payment', error);

        const { responseCode } = error;

        if (responseCode) {
          setPaymentFailed(true);
        } else {
          setFatalError(error);
        }
      })
      .finally(() => setIsPaymentInProgress(false));
  };

  useEffect(() => {
    if (fatalError) {
      throw fatalError;
    }
  }, [fatalError]);

  return (
    <ThemeProvider theme={baseTheme}>
      <PaymentProvider navigate={navigate} matter={matter}>
        <PageContainer>
          <PaymentHeader matter={matter} />

          <PaymentDrawer>
            <DrawerContent>
              <Heading2>Payment</Heading2>
              <Paragraph large>
                Payment can be made by debit or credit card.
              </Paragraph>
              {!captureContext && (
                <Paragraph large>Loading Payment gateway...</Paragraph>
              )}
              {paymentFailed && (
                <PaymentFailed>
                  Sorry, the payment did not go through. Please check your
                  details and try again.
                </PaymentFailed>
              )}
              {captureContext && (
                <CybersourcePaymentForm
                  captureContext={captureContext}
                  handlePayment={handlePayment}
                />
              )}
              {fatalError && (
                <Paragraph>
                  There was an error submitting your payment. Please contact
                  support.
                </Paragraph>
              )}
            </DrawerContent>
            <PaymentFooter amount={formatCurrency(amount)} />
          </PaymentDrawer>
        </PageContainer>
      </PaymentProvider>
      {isPaymentInProgress && (
        <Loader
          floating
          opacity={1}
          title="We are processing your payment..."
          subtitle="Please do not refresh this page"
        />
      )}
    </ThemeProvider>
  );
};

PaymentPayForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matter: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  requestPaymentToken: PropTypes.func.isRequired,
  requestPayment: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: (route, state, replace) => dispatch(push(route, state, replace)),
  requestPayment: data => dispatch(requestPaymentAction(data)).unwrap(),
  requestPaymentToken: () => dispatch(requestPaymentTokenAction()).unwrap(),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPayForm);
