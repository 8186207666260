import React from 'react';
import { ThemeProvider } from 'styled-components';

import { useAppSelector } from 'App/State/Store';
import IconAgreementDocument from 'Common/Assets/images/icon-agreement-document.svg';
import Paragraph from 'Common/UI/Text/Paragraph';
import HelpExternalButton from 'Common/UI/Button/HelpExternalButton';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import GenericButton from 'Common/UI/Button/GenericButton';
import { Label } from 'Common/UI/Text/Label';
import getNames from 'Common/Utils/getNames';
import {
  DocumentCardStatus,
  DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE,
  DOCUMENT_CARD_REQUIRED_SECTIONS_COMPLETE,
  DOCUMENT_CARD_COMPLETE,
  DOCUMENT_CARD_SELF_REQUESTED,
  DOCUMENT_CARD_OTHER_REQUESTED,
  DOCUMENT_CARD_PAYMENT_REQUIRED,
  DOC_TYPE_PROPERTY_AGREEMENT,
} from 'Common/constants';
import { getPricing } from 'Common/Utils/pricing';
import IconClock from 'Common/Assets/images/icon-clock.svg';
import TextButton from 'Common/UI/Button/TextButton';
import { Column } from 'Common/UI/Layout/Flex';
import { orangeTheme, cardThemes } from 'Common/Utils/theme';
import DownloadButton from 'Common/UI/Button/DownloadButton';
import colours from 'Common/Utils/colours';
import { ODRS_PAYMENT_REVIEW_ORDER_ROUTE } from 'Common/routes';
import isSafari from 'Common/Utils/isSafari';
import IconOther from 'Common/Assets/images/icon-users-other.svg';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import {
  CardWrapper,
  CardHeader,
  TickText,
  TickItem,
  CardContent,
  CardFooterWithDivider,
  CardFooterGrey,
  WaitingLabel,
  SafariDownloadNotice,
} from '../common';

type Props = {
  isFree: boolean;
  isPaid: boolean;
  status: DocumentCardStatus;
  setPartyFlags: (flags: Record<string, boolean>) => Promise<void>;
  openAgreementFunctions: Record<string, () => Promise<void>>;
  navigate: (route: string, state?: any) => void;
  isDisabled: boolean;
};

const PropertyAgreement: React.FC<Props> = ({
  isFree,
  isPaid,
  status = DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE,
  setPartyFlags,
  openAgreementFunctions,
  navigate,
  isDisabled,
}) => {
  const matter = useAppSelector(state => state.matter);
  const { table: pricingTable } = getPricing(matter);

  const names = getNames();

  let price = `$${pricingTable[DOC_TYPE_PROPERTY_AGREEMENT].priceExGST} +GST`;

  if (isFree) {
    price = 'Free';
  } else if (isPaid) {
    price = 'Paid';
  }

  const sharedContent = (
    <>
      <CardHeader
        icon={IconAgreementDocument}
        subTitle="Agreement"
        price={price}
        title="Property agreement"
      />
      <CardContent>
        <Paragraph>
          This is a document that sets out what you&apos;ve both agreed to do
          with your money and property.
        </Paragraph>
        <SpacedBox marginBottom={8}>
          <TickItem>
            <TickText>Lists your current money and property</TickText>
          </TickItem>
          <TickItem>
            <TickText>
              Shows how you&apos;ve agreed to divide your money and property
            </TickText>
          </TickItem>
          <TickItem>
            <TickText>Gives a written record of your agreement</TickText>
          </TickItem>
        </SpacedBox>
        <HelpExternalButton href="https://www.amica.gov.au/info-about-separating/money-and-property">
          Learn more
        </HelpExternalButton>
      </CardContent>
    </>
  );

  switch (status) {
    case DOCUMENT_CARD_REQUIRED_SECTIONS_COMPLETE:
      return (
        <CardWrapper
          data-cy="document-card-property"
          data-cy-status="sections-complete"
          disabled={isDisabled}
        >
          {sharedContent}
          <CardFooterWithDivider>
            <GenericButton
              fullWidth
              onClick={() => setPartyFlags({ canExportProperty: true })}
              disabled={isDisabled}
            >
              Create property agreement
            </GenericButton>

            <SpacedBox marginTop={8}>
              <Label>
                {isDisabled ? (
                  <>
                    You can only split superannuation by filing a consent order.
                    You have chosen to split superannuation, so you won’t be
                    able to create a property agreement. We recommend you seek
                    legal help or visit our{' '}
                    <ReferralLink href="https://amica.gov.au/how-it-works/money-and-property">
                      Money and Property
                    </ReferralLink>{' '}
                    page.
                  </>
                ) : (
                  <>
                    {names.other} will also need to agree to creating a property
                    agreement
                  </>
                )}
              </Label>
            </SpacedBox>
          </CardFooterWithDivider>
        </CardWrapper>
      );
    case DOCUMENT_CARD_SELF_REQUESTED:
      return (
        <CardWrapper
          borderColour={colours.midPurple}
          data-cy="document-card-property"
          data-cy-status="self-requested"
        >
          {sharedContent}
          <CardFooterGrey>
            <WaitingLabel>
              <img src={IconClock} alt="" /> Waiting for {names.other} to agree
            </WaitingLabel>
            <GenericButton
              subtle
              small
              onClick={() => setPartyFlags({ canExportProperty: false })}
            >
              Undo
            </GenericButton>
          </CardFooterGrey>
        </CardWrapper>
      );
    case DOCUMENT_CARD_OTHER_REQUESTED:
      return (
        <CardWrapper
          borderColour={colours.midTeal}
          data-cy="document-card-property"
          data-cy-status="other-requested"
        >
          {sharedContent}
          <CardFooterGrey>
            <WaitingLabel>
              <img src={IconOther} alt="" /> {names.other} has requested to
              create your property agreement
            </WaitingLabel>
            <Column>
              <ThemeProvider theme={cardThemes.other}>
                <GenericButton
                  fullWidth
                  onClick={() => setPartyFlags({ canExportProperty: true })}
                >
                  Create property agreement
                </GenericButton>
                <TextButton
                  fullWidth
                  onClick={() =>
                    setPartyFlags({ notReadyToExportProperty: true })
                  }
                >
                  No thanks
                </TextButton>
              </ThemeProvider>
            </Column>
          </CardFooterGrey>
        </CardWrapper>
      );
    case DOCUMENT_CARD_PAYMENT_REQUIRED:
      return (
        <CardWrapper
          data-cy="document-card-property"
          data-cy-status="payment-required"
        >
          {sharedContent}
          <CardFooterWithDivider>
            <ThemeProvider theme={orangeTheme}>
              <GenericButton
                fullWidth
                onClick={() => navigate(ODRS_PAYMENT_REVIEW_ORDER_ROUTE)}
                disabled={isDisabled}
              >
                Pay for document
              </GenericButton>
            </ThemeProvider>

            <SpacedBox marginTop={4}>
              <Label>
                You will be able to download this document once either of you
                pay for it
              </Label>
            </SpacedBox>
          </CardFooterWithDivider>
        </CardWrapper>
      );
    case DOCUMENT_CARD_COMPLETE:
      return (
        <CardWrapper data-cy="document-card-property" data-cy-status="complete">
          {sharedContent}
          <CardFooterWithDivider>
            <DownloadButton
              onClick={openAgreementFunctions.propertyAgreement}
              disabled={isDisabled}
            >
              Download property agreement
            </DownloadButton>

            {isSafari() && (
              <SafariDownloadNotice>
                Downloading a document can sometimes take up to 15 seconds to
                load.
              </SafariDownloadNotice>
            )}
          </CardFooterWithDivider>
        </CardWrapper>
      );
    case DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE:
    default:
      return (
        <CardWrapper
          data-cy="document-card-property"
          data-cy-status="sections-incomplete"
        >
          {sharedContent}
        </CardWrapper>
      );
  }
};

export default PropertyAgreement;
