import React from 'react';
import { Heading3 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';

const MajorDecisions = (
  <>
    <Heading3>Major decisions</Heading3>
    <Paragraph>
      Major decisions about raising your children can be about anything that
      substantially affects their life in the long-term, like what school they
      will attend, what religion they practice, or what kind of medical
      treatment they receive.
    </Paragraph>
    <Paragraph>
      Where it is safe to do so, parents should be encouraged to consult with 
      one another about what is in the best interests of their children.
    </Paragraph>
  </>
);

export default MajorDecisions;
