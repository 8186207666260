import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MatterProps from 'Common/Utils/MatterProps';

export const ScheduleContext = createContext();

const convertPartyDataToBools = nights => {
  const numberOfNights = nights.length;
  const numberOfWeeks = Math.floor(numberOfNights / 7);
  const currentWeeks = [];

  for (let weekNumber = 0; weekNumber < numberOfWeeks; weekNumber += 1) {
    const newWeek = [];

    for (let dayNumber = 0; dayNumber < 7; dayNumber += 1) {
      const nightValue = nights[weekNumber * 7 + dayNumber];

      newWeek.push(nightValue === MatterProps('self.party'));
    }

    currentWeeks.push(newWeek);
  }

  return currentWeeks.length > 0 ? currentWeeks : null;
};

export const ScheduleProvider = ({
  children,
  onSubmit,
  onInputChange,
  value = [],
  isActive,
}) => {
  // template for an empty week
  // true means this party has the children
  // false means other party has the children
  // defaults to false then user toggles days to true
  const emptyWeek = [false, false, false, false, false, false, false];

  const maxWeeks = 4; // the maximum number of weeks that can be added

  const buildWeeksValue = () => {
    const data = convertPartyDataToBools(value) || [emptyWeek];
    return [...data];
  };

  const [weeks, setWeeks] = useState(buildWeeksValue());
  const [screen, setScreen] = useState('edit'); // edit or confirm

  const numberOfWeeks = weeks.length;

  // loads existing data, if available
  // i couldn't for the life of me work out how to stop it getting stuck in an infinite loop here due to
  // value being an array, so I cheated and am getting it to check via JSON if it's updated
  useEffect(() => {
    setWeeks(buildWeeksValue());
  }, [JSON.stringify(value)]);

  // checks if the schedule is empty, a.k.a has been started yet
  const isScheduleEmpty = weeks.every(week => week.every(day => !day));

  // flatten the weeks array in to a flat array of days
  const convertWeeksToDays = weeksData =>
    weeksData.reduce((daysArray, week) => [...daysArray, ...week]);

  // counts the number of nights that the given party has currently assigned to them
  const countNights = ({ self = false }) =>
    weeks.reduce(
      (count, week) => count + week.filter(day => day === self).length,
      0
    );

  const updateQuestionValues = weeksData => {
    setWeeks(weeksData);

    if (onInputChange) {
      const days = convertWeeksToDays(weeksData);

      const self = MatterProps('self.party');
      const other = MatterProps('other.party');

      const newValue = days.map(day => (day ? self : other));

      onInputChange('schedule', newValue);
    }
  };

  const canAddWeek = weeks.length < maxWeeks;

  const toggleNight = (weekNumber, dayNumber) => {
    const updatedWeeks = [...weeks];

    const currentNightValue = updatedWeeks[weekNumber][dayNumber];

    updatedWeeks[weekNumber][dayNumber] = currentNightValue !== true;

    updateQuestionValues(updatedWeeks);
  };

  const getNumberNightsWithSelf = () => {
    if (isScheduleEmpty) {
      return 0;
    }

    return countNights({ self: true });
  };

  const getNumberNightsWithOther = () => {
    if (isScheduleEmpty) {
      return 0;
    }

    return countNights(false);
  };

  // calculates the number of handovers in the schedule
  const getNumberOfHandovers = () => {
    const days = convertWeeksToDays(weeks);

    return days.reduce((count, day, index, daysArray) => {
      if (index === 0 || day !== daysArray[index - 1]) {
        return count + 1;
      }

      return count;
    }, -1);
  };

  // adds a new week
  const addWeek = () => {
    if (canAddWeek) {
      const newWeeksData = weeks.concat([emptyWeek]);
      setWeeks(newWeeksData);
      updateQuestionValues(newWeeksData);
    }
  };

  // removes the last week (data for that week will be deleted too)
  const removeWeek = () => updateQuestionValues(weeks.slice(0, -1));

  return (
    <ScheduleContext.Provider
      value={{
        onSubmit,
        onInputChange,
        weeks,
        numberOfWeeks,
        toggleNight,
        addWeek,
        removeWeek,
        getNumberNightsWithSelf,
        getNumberNightsWithOther,
        isScheduleEmpty,
        getNumberOfHandovers,
        screen,
        setScreen,
        isActive,
        canAddWeek,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

ScheduleProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
  isActive: PropTypes.bool.isRequired,
};

export const ScheduleConsumer = ({ children }) => (
  <ScheduleContext.Consumer>{children}</ScheduleContext.Consumer>
);

ScheduleConsumer.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: () => null,
  onInputChange: () => null,
};
