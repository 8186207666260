import React, { useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import {
  INVITE_OTHER_PARTY,
  RELATIONSHIP_DASHBOARD_NOT_COMPLETED,
  SUGGESTED_DIVISION_START,
  SUGGESTED_DIVISION_WAITING,
  SUGGESTED_DIVISION_READY,
  SUGGESTED_DIVISION_AGREED,
  SUGGESTED_DIVISION_YOU_MADE_OFFER,
  SUGGESTED_DIVISION_OTHER_MADE_OFFER,
  SUGGESTED_DIVISION_OTHER_MADE_COUNTER_OFFER,
  DEFAULT_SELF_TEXT,
  DEFAULT_OTHER_TEXT,
  PROPERTY_DASHBOARD_COMPLETED,
  ASSET_SPLIT_VIEWED_BY_OWED_PARTY,
  ASSET_SPLIT_OTHER_PARTY_COMPLETING,
  DOCUMENT_TYPES_OTHER_NOT_READY,
  DOCUMENT_TYPES_WAITING_FOR_OTHER,
  DOC_TYPE_CONSENT_ORDERS,
  DOC_TYPE_PROPERTY_AGREEMENT,
  SUGGESTED_DIVISION_CANT_CONTINUE,
  ASSET_SPLIT_OTHER_PARTY_INITIAL_OFFER,
  ASSET_SPLIT_OTHER_PARTY_COUNTER_OFFER,
  ASSET_SPLIT_OFFER_SENT,
  DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED,
  DOCUMENT_TYPES_PROPERTY_AGREEMENT_PAYMENT_REQUIRED,
  ASSET_SPLIT_SELF_PARTY_COMPLETING,
  DASHBOARD_TAB_LOCKED,
  RESET_SUGGESTED_DIVISION_WAITING_FOR_SELF,
  RESET_SUGGESTED_DIVISION_WAITING_FOR_OTHER,
  RESET_SUGGESTED_DIVISION_OTHER_NOT_READY,
  PARTY_A,
  PROPERTY_AGREEMENT_NEEDS_CONFIRMATION,
  CONSENT_ORDER_NEEDS_CONFIRMATION,
  SUPERANNUATION_BALANCE_REQUEST_CANCELLED_REQUESTEE,
  SUPERANNUATION_REQUEST_BALANCE_STARTED_REQUESTER,
  SUPERANNUATION_REQUEST_BALANCE_UPLOAD_LETTER_REQUESTER,
  SUPERANNUATION_REQUEST_BALANCE_COMPLETED_REQUESTER,
  SUPERANNUATION_REQUEST_BALANCE_IN_PROGRESS_REQUESTEE,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_SELF,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTER,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTEE,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_REJECTED_REQUESTEE,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_REJECTED_REQUESTER,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_OTHER,
} from 'Common/constants';
import {
  ODRS_DIVISION_SPLIT_INTRO_ROUTE,
  ODRS_INVITE_PARTNER_ROUTE,
  ODRS_DIVISION_SPLIT_VIEW_ROUTE,
  ODRS_DIVISION_ASSETS_INFO,
  ODRS_DIVISION_ASSETS_INFO_INTRO,
  ODRS_DIVISION_ASSETS_VIEW_OFFER,
  ODRS_PAYMENT_REVIEW_ORDER_ROUTE,
  ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
} from 'Common/routes';
import capitaliseText from 'Common/Utils/capitaliseText';

import {
  setPartyFlagsAction,
  resetAgreementStateAction,
} from 'App/State/MatterActions';
import AssetProps from 'Common/Data/App/assetProperties';
import { sessionStorageSave } from 'Common/Utils/sessionStorage';
import MatterProps from 'Common/Utils/MatterProps';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import TextButton from 'Common/UI/Button/TextButton';
import { getSection } from 'Common/Data/App/appSections';
import { useAppSelector } from 'App/State/Store';
import { localStorageLoad, localStorageSave } from 'Common/Utils/localStorage';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import Paragraph, { ParagraphNoMarginBottom } from 'Common/UI/Text/Paragraph';
import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import GenericButton from 'Common/UI/Button/GenericButton';
import PropertyDashboardCompleted from 'Common/UI/Banners/PropertyDashboardCompleted';
import {
  PropertyAgreementNeedsConfirmation,
  ConsentOrderNeedsConfirmation,
} from 'Common/UI/Banners/DocumentTypesNeedsConfirmation';
import DocumentTypesOtherNotReady from 'Common/UI/Banners/DocumentTypesOtherNotReady';
import DocumentTypesWaitingForOther from 'Common/UI/Banners/DocumentTypesWaitingForOther';
import AssetSplitSelfOfferInProgress from 'Common/UI/Banners/AssetSplitSelfOfferInProgress';
import DashboardTabLocked from 'Common/UI/Banners/DashboardTabLocked';
import { RelationshipDashboardNotCompletedProperty } from 'Common/UI/Banners/RelationshipDashboardNotCompleted';
import {
  ResetSuggestedDivisionWaitingForSelf,
  ResetSuggestedDivisionWaitingForOther,
  ResetSuggestedDivisionOtherNotReady,
} from 'Common/UI/Banners/ResetSuggestedDivision';
import { DashboardContext } from 'Common/UI/DashboardContext';

import { SuperannuationBalanceRequestCancelledRequestee } from 'Common/UI/Banners/SuperannuationBalanceRequestCancelledRequestee';
import { SuperannuationRequestBalanceStartedRequester } from 'Common/UI/Banners/SuperannuationRequestBalanceStartedRequester';
import { SuperannuationRequestBalanceUploadLetterRequester } from 'Common/UI/Banners/SuperannuationRequestBalanceUploadLetterRequester';
import { SuperannuationBalanceRequestCompletedBothParties } from 'Common/UI/Banners/SuperannuationBalanceRequestCompletedBothParties';
import { SuperannuationBalanceRequestInProgressRequestee } from 'Common/UI/Banners/SuperannuationBalanceRequestInProgressRequestee';
import { SuperSplittingRemoveSuperannuationWaitingForSelf } from 'Common/UI/Banners/SuperSplitting/RemoveSuperannuationWaitingForSelf';
import { SuperSplittingRemoveSuperannuationApprovedRequester } from 'Common/UI/Banners/SuperSplitting/RemoveSuperannuationApprovedRequester';
import { SuperSplittingRemoveSuperannuationApprovedRequestee } from 'Common/UI/Banners/SuperSplitting/RemoveSuperannuationApprovedRequestee';
import { SuperSplittingRemoveSuperannuationRejectedRequestee } from 'Common/UI/Banners/SuperSplitting/RemoveSuperannuationRejectedRequestee';
import { SuperSplittingRemoveSuperannuationRejectedRequester } from 'Common/UI/Banners/SuperSplitting/RemoveSuperannuationRejectedRequester';
import { SuperSplittingRemoveSuperannuationWaitingForOther } from 'Common/UI/Banners/SuperSplitting/RemoveSuperannuationWaitingForOther';

const ButtonContainer = styled.div`
  ${GenericButton}:first-child {
    margin-bottom: ${({ theme }) => theme.padding.xsmall}px;
  }
`;

/**
 * Given the appstate, will return the corresponding banner
 */
const SettlementBannerContent = ({
  navigate,
  confirmStatements,
  readyToExportProperty,
  readyToStartConsentOrders,
  notReadyToExportProperty,
  notReadyToStartConsentOrders,
  resetPropertyAgreementState,
  resetConsentOrdersAgreementState,
  appState,
  section,
  page,
}) => {
  const matter = useAppSelector(state => state.matter);

  const yourName = MatterProps('self.firstname', DEFAULT_SELF_TEXT);
  const otherName = MatterProps('other.firstname', DEFAULT_OTHER_TEXT);

  const navigateWithAssetSplitIntroGuard = route => {
    if (MatterProps('self.hasSeenAssetTransferIntro', false)) {
      navigate(route);
    } else {
      navigate(ODRS_DIVISION_ASSETS_INFO_INTRO, { nextRoute: route });
    }
  };

  const { numberOfOffers } = AssetProps();

  const { withLoading } = usePromiseWithLoading();

  const { updateAppState } = useContext(DashboardContext);

  let firstInProgressPageInSection;
  let pages;
  switch (appState) {
    case DASHBOARD_TAB_LOCKED:
      pages = getSection(section)?.children || [];
      firstInProgressPageInSection = pages.find(
        ({ generateStatus }) =>
          generateStatus && generateStatus(matter) === 'not-completed'
      );

      return (
        <DashboardTabLocked
          navigate={navigate}
          firstInProgressPageInSection={firstInProgressPageInSection}
        />
      );
    case RELATIONSHIP_DASHBOARD_NOT_COMPLETED:
      return (
        <RelationshipDashboardNotCompletedProperty otherName={otherName} />
      );
    case RESET_SUGGESTED_DIVISION_WAITING_FOR_SELF:
      return <ResetSuggestedDivisionWaitingForSelf otherName={otherName} />;
    case RESET_SUGGESTED_DIVISION_WAITING_FOR_OTHER:
      return <ResetSuggestedDivisionWaitingForOther otherName={otherName} />;
    case RESET_SUGGESTED_DIVISION_OTHER_NOT_READY:
      return <ResetSuggestedDivisionOtherNotReady otherName={otherName} />;
    case INVITE_OTHER_PARTY:
      return (
        <>
          <Heading2MarginTopNone>
            Invite your former partner to amica
          </Heading2MarginTopNone>
          <p />
          <GenericButton
            small
            onClick={() =>
              navigate(ODRS_INVITE_PARTNER_ROUTE, { fromDashboard: true })
            }
          >
            Send an invite to their email
          </GenericButton>
        </>
      );
    case SUGGESTED_DIVISION_START: {
      const {
        flags: {
          hasResetSuggestedDivision = false,
          suggestedDivisionResetInitiatedBy = PARTY_A,
        },
      } = matter;

      if (
        hasResetSuggestedDivision &&
        !localStorageLoad('hasDismissedResetSuggestedDivisionBanner')
      ) {
        if (suggestedDivisionResetInitiatedBy === matter.self.party) {
          return (
            <>
              <Heading2MarginTopNone data-cy="divi-banner">
                {otherName} has agreed to your request to restart asset division
              </Heading2MarginTopNone>
              <Paragraph>
                If your information is incorrect, you can reopen your
                statements.
              </Paragraph>
              <GenericButton
                fullWidth
                onClick={() => {
                  localStorageSave(
                    'hasDismissedResetSuggestedDivisionBanner',
                    true
                  );
                  updateAppState();
                  navigate(ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE);
                }}
                small
              >
                Review statements
              </GenericButton>
              <TextButton
                fullWidth
                onClick={() => {
                  localStorageSave(
                    'hasDismissedResetSuggestedDivisionBanner',
                    true
                  );
                  updateAppState();
                }}
                small
                color="#FFFFFF"
              >
                Dismiss
              </TextButton>
            </>
          );
        }

        return (
          <>
            <Heading2MarginTopNone data-cy="divi-banner">
              You have agreed to restart the asset division section
            </Heading2MarginTopNone>
            <Paragraph>
              If your information is incorrect, you can reopen your statements.
            </Paragraph>
            <GenericButton
              fullWidth
              onClick={() => {
                localStorageSave(
                  'hasDismissedResetSuggestedDivisionBanner',
                  true
                );
                updateAppState();
                navigate(ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE);
              }}
              small
            >
              Review statements
            </GenericButton>
            <TextButton
              fullWidth
              onClick={() => {
                localStorageSave(
                  'hasDismissedResetSuggestedDivisionBanner',
                  true
                );
                updateAppState();
              }}
              small
              color="#FFFFFF"
            >
              Dismiss
            </TextButton>
          </>
        );
      }
      return (
        <>
          <Heading2MarginTopNone data-cy="divi-banner">
            You can get started on the suggested division once you confirm you
            are ready
          </Heading2MarginTopNone>
          <Paragraph>
            By getting started, you are confirming that all the information you
            entered is correct. Suggested division is based on the information
            you and {otherName} entered. If your information is incorrect, you
            can reopen your statements.
          </Paragraph>
          <GenericButton
            fullWidth
            onClick={() => {
              withLoading(confirmStatements).then(() =>
                localStorageSave(
                  'hasDismissedResetSuggestedDivisionBanner',
                  false
                )
              );
            }}
            small
          >
            Get started
          </GenericButton>
        </>
      );
    }
    case SUGGESTED_DIVISION_WAITING:
      return (
        <>
          <Heading2MarginTopNone data-cy="divi-banner">
            {`Waiting on ${otherName} to also confirm they 
          are ready to move to the suggested division`}
          </Heading2MarginTopNone>
          <ParagraphNoMarginBottom>
            {`Once ${otherName} confirms they agree with all the statements, 
            you will both be able to get started on the suggested division.`}
          </ParagraphNoMarginBottom>
        </>
      );
    case SUGGESTED_DIVISION_READY:
      return (
        <>
          <Heading2MarginTopNone data-cy="divi-banner">
            You can now get started on the suggested division
          </Heading2MarginTopNone>
          <Paragraph>
            amica has come up with a suggested division of money and property
            for you and {otherName}. You will have the chance to agree or make
            your own offer.
          </Paragraph>
          <GenericButton
            fullWidth
            onClick={() =>
              navigate(ODRS_DIVISION_SPLIT_INTRO_ROUTE, { first: true })
            }
            small
          >
            Get Started
          </GenericButton>
        </>
      );
    case SUGGESTED_DIVISION_YOU_MADE_OFFER:
      return (
        <>
          <Heading2MarginTopNone data-cy="divi-banner">
            {`Hi ${yourName}, your offer has been sent to ${otherName}`}
          </Heading2MarginTopNone>
          <Paragraph>
            We&#39;ll let you know once they have reviewed your offer.
          </Paragraph>
          <GenericButton
            fullWidth
            onClick={() => navigate(ODRS_DIVISION_SPLIT_VIEW_ROUTE)}
            small
          >
            View your offer
          </GenericButton>
        </>
      );
    case SUGGESTED_DIVISION_OTHER_MADE_OFFER:
      return (
        <>
          <Heading2MarginTopNone data-cy="divi-banner">
            You can now get started on the suggested division
          </Heading2MarginTopNone>
          <Paragraph>
            {`${capitaliseText(
              otherName
            )} has already viewed amica's suggested offer and 
            has negotiated their own offer`}
          </Paragraph>
          <GenericButton
            fullWidth
            onClick={() =>
              navigate(ODRS_DIVISION_SPLIT_INTRO_ROUTE, { first: false })
            }
            small
          >
            Get Started
          </GenericButton>
        </>
      );
    case SUGGESTED_DIVISION_OTHER_MADE_COUNTER_OFFER:
      return (
        <>
          <Heading2MarginTopNone data-cy="divi-banner">
            {`Hi ${yourName}, ${otherName} has made a counter offer`}
          </Heading2MarginTopNone>
          <Paragraph>{`${capitaliseText(
            otherName
          )} has viewed your offer and has made a counter offer.`}</Paragraph>
          <GenericButton
            fullWidth
            onClick={() => navigate(ODRS_DIVISION_SPLIT_VIEW_ROUTE)}
            small
          >
            Get Started
          </GenericButton>
        </>
      );
    case SUGGESTED_DIVISION_AGREED:
      return (
        <>
          <Heading2MarginTopNone>
            Great! You have agreed on your percentages. Now you can work out how
            you would like to divide your assets
          </Heading2MarginTopNone>
          <Paragraph>
            In the next section, you can work out whether you will sell or
            transfer property and or money to reflect the percentages
            you&apos;ve agreed to.
          </Paragraph>
          <GenericButton
            fullWidth
            onClick={() =>
              MatterProps('self.hasSeenAssetTransferIntro', false)
                ? navigate(ODRS_DIVISION_ASSETS_INFO)
                : navigate(ODRS_DIVISION_ASSETS_INFO_INTRO)
            }
            small
          >
            Get Started
          </GenericButton>
        </>
      );
    case DOCUMENT_TYPES_WAITING_FOR_OTHER:
      return <DocumentTypesWaitingForOther otherName={otherName} />;
    case PROPERTY_AGREEMENT_NEEDS_CONFIRMATION:
      return (
        <PropertyAgreementNeedsConfirmation
          navigate={navigate}
          otherName={otherName}
          readyToExport={() => withLoading(readyToExportProperty)}
          notReadyToExport={() => withLoading(notReadyToExportProperty)}
        />
      );
    case CONSENT_ORDER_NEEDS_CONFIRMATION:
      return (
        <ConsentOrderNeedsConfirmation
          navigate={navigate}
          otherName={otherName}
          readyToExport={() => withLoading(readyToStartConsentOrders)}
          notReadyToExport={() => withLoading(notReadyToStartConsentOrders)}
        />
      );
    case DOCUMENT_TYPES_OTHER_NOT_READY:
      return (
        <DocumentTypesOtherNotReady
          otherName={otherName}
          resetPropertyAgreementState={() =>
            withLoading(resetPropertyAgreementState)
          }
          resetConsentOrdersAgreementState={() =>
            withLoading(resetConsentOrdersAgreementState)
          }
        />
      );
    case SUGGESTED_DIVISION_CANT_CONTINUE:
      return (
        <SpacedBox marginBottom="xxsmall">
          <Heading2MarginTopNone>
            Unfortunately you will be unable to do the asset division section
          </Heading2MarginTopNone>
          <Paragraph>
            Unfortunately, you won’t be able to divide your money and property
            in amica since the total value of your money and property is
            negative. This means you have more liabilities than assets.
          </Paragraph>
          <Paragraph>
            You can still save a record of your progress in the account section,
            or you can get help and support{' '}
            <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
              here
            </ReferralLink>
            .
          </Paragraph>
          <Paragraph>
            You can still edit your cards if you don’t think your information is
            accurate.
          </Paragraph>
        </SpacedBox>
      );
    case DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED:
      return (
        <>
          <Heading2MarginTopNone>
            Great! you have completed your application for consent orders
          </Heading2MarginTopNone>
          <Paragraph large>
            You will need to pay to view or download your consent order
            application document. You can pay now or return when you are ready
            to pay.
          </Paragraph>
          <ButtonContainer>
            <GenericButton
              fullWidth
              onClick={() => navigate(ODRS_PAYMENT_REVIEW_ORDER_ROUTE)}
              small
            >
              Pay now
            </GenericButton>
            <TextButton
              fullWidth
              onClick={() => {
                sessionStorageSave(
                  `${DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED}Dismissed`,
                  true
                );
                updateAppState();
              }}
              small
              color="#FFFFFF"
            >
              Pay later
            </TextButton>
          </ButtonContainer>
        </>
      );
    case DOCUMENT_TYPES_PROPERTY_AGREEMENT_PAYMENT_REQUIRED:
      return (
        <>
          <Heading2MarginTopNone>
            Great! You have agreed to create a property agreement
          </Heading2MarginTopNone>
          <Paragraph large>
            You will need to pay to view or download your property agreement.
            You can pay now or return when you are ready to pay.
          </Paragraph>
          <ButtonContainer>
            <GenericButton
              fullWidth
              onClick={() => navigate(ODRS_PAYMENT_REVIEW_ORDER_ROUTE)}
              small
            >
              Pay now
            </GenericButton>
            <TextButton
              fullWidth
              onClick={() => {
                sessionStorageSave(
                  `${DOCUMENT_TYPES_PROPERTY_AGREEMENT_PAYMENT_REQUIRED}Dismissed`,
                  true
                );
                updateAppState();
              }}
              small
              color="#FFFFFF"
            >
              Pay later
            </TextButton>
          </ButtonContainer>
        </>
      );
    case PROPERTY_DASHBOARD_COMPLETED:
      return <PropertyDashboardCompleted page={page} navigate={navigate} />;
    case ASSET_SPLIT_SELF_PARTY_COMPLETING:
      return (
        <AssetSplitSelfOfferInProgress
          navigateWithAssetSplitIntroGuard={navigateWithAssetSplitIntroGuard}
          otherName={otherName}
        />
      );
    case ASSET_SPLIT_OTHER_PARTY_COMPLETING:
      return (
        <>
          <Heading2MarginTopNone>
            {otherName} is completing the asset division section.
          </Heading2MarginTopNone>
          <Paragraph>
            {otherName} is working out a proposal for how you should divide your
            assets. Once they send it to you, you can agree or respond with your
            own proposal.
          </Paragraph>
          <GenericButton
            fullWidth
            onClick={() => {
              sessionStorageSave(
                `AssetSplitOfferOtherPartyCompleting-${numberOfOffers}`,
                true
              );
              updateAppState();
            }}
            small
          >
            Got it
          </GenericButton>
        </>
      );
    case ASSET_SPLIT_VIEWED_BY_OWED_PARTY:
    case ASSET_SPLIT_OTHER_PARTY_INITIAL_OFFER:
      return (
        <>
          <Heading2MarginTopNone>
            {otherName} has made a proposal for how you can divide your assets
            to reach your agreed percentages
          </Heading2MarginTopNone>
          <Paragraph>
            You can agree or respond with your own proposal.
          </Paragraph>
          <GenericButton
            fullWidth
            onClick={() =>
              navigateWithAssetSplitIntroGuard(ODRS_DIVISION_ASSETS_VIEW_OFFER)
            }
            small
          >
            View proposal
          </GenericButton>
        </>
      );
    case ASSET_SPLIT_OTHER_PARTY_COUNTER_OFFER:
      return (
        <>
          <Heading2MarginTopNone>
            {otherName} has made a counter proposal for how you can divide your
            assets to reach your agreed percentages
          </Heading2MarginTopNone>
          <Paragraph>
            You can agree or respond with your own proposal.
          </Paragraph>
          <GenericButton
            fullWidth
            onClick={() =>
              navigateWithAssetSplitIntroGuard(ODRS_DIVISION_ASSETS_VIEW_OFFER)
            }
            small
          >
            View proposal
          </GenericButton>
        </>
      );
    case ASSET_SPLIT_OFFER_SENT:
      return (
        <>
          <Heading2MarginTopNone>
            {otherName} is viewing your asset division proposal.
          </Heading2MarginTopNone>
          <Paragraph>
            Once {otherName} has finished viewing your proposal, they can either
            agree or respond with their own proposal.
          </Paragraph>
          <GenericButton
            fullWidth
            onClick={() => {
              sessionStorageSave(
                `AssetSplitOfferSentToOtherDismissed-${numberOfOffers}`,
                true
              );
              updateAppState();
            }}
            small
          >
            Got it
          </GenericButton>
        </>
      );
    case SUPERANNUATION_BALANCE_REQUEST_CANCELLED_REQUESTEE:
      return (
        <SuperannuationBalanceRequestCancelledRequestee otherName={otherName} />
      );
    case SUPERANNUATION_REQUEST_BALANCE_STARTED_REQUESTER:
      return (
        <SuperannuationRequestBalanceStartedRequester
          otherName={otherName}
          updateAppState={updateAppState}
        />
      );
    case SUPERANNUATION_REQUEST_BALANCE_UPLOAD_LETTER_REQUESTER:
      return (
        <SuperannuationRequestBalanceUploadLetterRequester
          otherName={otherName}
          updateAppState={updateAppState}
        />
      );
    case SUPERANNUATION_REQUEST_BALANCE_COMPLETED_REQUESTER:
      return (
        <SuperannuationBalanceRequestCompletedBothParties
          otherName={otherName}
          updateAppState={updateAppState}
        />
      );
    case SUPERANNUATION_REQUEST_BALANCE_IN_PROGRESS_REQUESTEE:
      return (
        <SuperannuationBalanceRequestInProgressRequestee
          otherName={otherName}
          updateAppState={updateAppState}
        />
      );
    case SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_SELF:
      return (
        <SuperSplittingRemoveSuperannuationWaitingForSelf
          otherName={otherName}
        />
      );
    case SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTER:
      return (
        <SuperSplittingRemoveSuperannuationApprovedRequester
          otherName={otherName}
        />
      );
    case SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTEE:
      return (
        <SuperSplittingRemoveSuperannuationApprovedRequestee
          otherName={otherName}
        />
      );
    case SUPER_SPLITTING_REMOVE_SUPERANNUATION_REJECTED_REQUESTEE:
      return (
        <SuperSplittingRemoveSuperannuationRejectedRequestee
          otherName={otherName}
        />
      );
    case SUPER_SPLITTING_REMOVE_SUPERANNUATION_REJECTED_REQUESTER:
      return (
        <SuperSplittingRemoveSuperannuationRejectedRequester
          otherName={otherName}
        />
      );
    case SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_OTHER:
      return (
        <SuperSplittingRemoveSuperannuationWaitingForOther
          otherName={otherName}
          updateAppState={updateAppState}
        />
      );
    default:
      return null;
  }
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  confirmStatements: () =>
    dispatch(setPartyFlagsAction({ hasConfirmedStatements: true })),
  navigate: (to, state) => dispatch(push(to, state)),
  resetPropertyAgreementState: () =>
    dispatch(resetAgreementStateAction({ name: DOC_TYPE_PROPERTY_AGREEMENT })),
  resetConsentOrdersAgreementState: () =>
    dispatch(resetAgreementStateAction({ name: DOC_TYPE_CONSENT_ORDERS })),
  readyToExportProperty: () =>
    dispatch(setPartyFlagsAction({ canExportProperty: true })),
  readyToStartConsentOrders: () =>
    dispatch(setPartyFlagsAction({ canStartConsentOrders: true })),
  notReadyToExportProperty: () =>
    dispatch(setPartyFlagsAction({ notReadyToExportProperty: true })),
  notReadyToStartConsentOrders: () =>
    dispatch(setPartyFlagsAction({ notReadyToStartConsentOrders: true })),
});

SettlementBannerContent.propTypes = {
  navigate: PropTypes.func.isRequired,
  confirmStatements: PropTypes.func,
  readyToExportProperty: PropTypes.func.isRequired,
  readyToStartConsentOrders: PropTypes.func.isRequired,
  notReadyToExportProperty: PropTypes.func.isRequired,
  notReadyToStartConsentOrders: PropTypes.func.isRequired,
  resetPropertyAgreementState: PropTypes.func.isRequired,
  resetConsentOrdersAgreementState: PropTypes.func.isRequired,
  appState: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
};

SettlementBannerContent.defaultProps = {
  confirmStatements: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettlementBannerContent);
