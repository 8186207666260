/**
 * THIS IS A VERSIONED FILE - see readme for more info
 */

import { checkPageUnLocked } from 'Common/Utils/consentOrderPageChecks';
import {
  DOC_TYPE_CONSENT_ORDERS,
  Agreements,
  STATUS_APPROVED,
  AMICA_FREE_INDIVIDUAL_INCOME_THRESHOLD,
  DOC_TYPES_TO_KEYS,
} from 'Common/constants';
import { getPricing } from 'Common/Utils/pricing';
import Matter from 'Common/Data/Types/matter';

import {
  hasItemBeenPaidFor,
  getCompletedOrders,
  getOrderWithItems,
  getTotalPrice,
} from '../20221124/VERSIONED_Payments';

export const areBothPartiesReadyToExport = (
  { partyA, partyB }: Matter,
  item: Agreements
) => {
  if (item === DOC_TYPE_CONSENT_ORDERS) {
    return checkPageUnLocked.completed.unlocked();
  }

  return (
    partyA[`canExport${DOC_TYPES_TO_KEYS[item]}`] &&
    partyB[`canExport${DOC_TYPES_TO_KEYS[item]}`]
  );
};

export const isAmicaFree = ({ items }: Matter) => {
  const aboutYouA = items.find(
    item => item.SectionID === 'aboutyouA' && item.status === STATUS_APPROVED
  );
  const aboutYouB = items.find(
    item => item.SectionID === 'aboutyouB' && item.status === STATUS_APPROVED
  );

  if (!aboutYouA || !aboutYouB) {
    return false;
  }

  // free only if both parties incomes are under the threshold
  return (
    Number(aboutYouA.earnings || 0) <= AMICA_FREE_INDIVIDUAL_INCOME_THRESHOLD &&
    Number(aboutYouB.earnings || 0) <= AMICA_FREE_INDIVIDUAL_INCOME_THRESHOLD
  );
};

export const isPaymentDue = (matter: Matter, item: Agreements) => {
  if (isAmicaFree(matter)) {
    return false;
  }

  const { list: pricingList } = getPricing(matter);

  if (!pricingList.find(i => i.type === item)) {
    return false;
  }

  return (
    areBothPartiesReadyToExport(matter, item) &&
    !hasItemBeenPaidFor(matter, item)
  );
};

export const getItemsAwaitingPayment = (matter: Matter) => {
  const { list: pricingList } = getPricing(matter);
  return pricingList.reduce((acc: Agreements[], item) => {
    if (isPaymentDue(matter, item.type)) {
      return [...acc, item.type];
    }

    return acc;
  }, []);
}
  

export const isPaymentActionNeeded = (matter: Matter) =>
  getItemsAwaitingPayment(matter).length > 0;

export {
  hasItemBeenPaidFor,
  getCompletedOrders,
  getOrderWithItems,
  getTotalPrice,
};
