import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import MatterProps from 'Common/Utils/MatterProps';
import AssetProps from 'Common/Data/App/assetProperties';
import { ODRS_DIVISION_ASSETS_MESSAGING } from 'Common/routes';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import PageContent from 'Common/UI/Layout/PageContent';
import PageContainer from 'Common/UI/Layout/PageContainer';
import { Heading2 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { blackTheme } from 'Common/Utils/theme';
import CardWarning from 'Common/UI/Card/indicators/CardWarning';
import { useAppDispatch } from 'App/State/Store';
import push from 'Common/Utils/push';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { isSuperSplittingSupported } from 'Common/Utils/versionHelpers';
import Header from './Header';
import DialogSingleButton from '../Dialog/DialogSingleButton';
import { DivisionAssetsProvider } from './DivisionAssetsContext';
import SelectedAssetsList from '../Components/SelectedAssetsList';
import SuperannuationInfoMessage from '../Components/SuperannuationInfoMessage';

const ContentHeader = styled.div`
  padding: ${props => props.theme.padding.small}px;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  border-bottom: 1px solid ${props => props.theme.colours.lightGrey};
`;

const FixedFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

const StyledPageContent = styled(PageContent)`
  padding-bottom: 240px;
`;

const DivisionAssetsConfirm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = (route: string) => dispatch(push(route));

  const {
    haveAllJointAssetsBeenAssigned,
    canSubmitOffer,
    isSuperIncludedInOffer,
    isOwingParty,
    shortfallAmount,
  } = AssetProps();

  const [dialog, setDialog] = useState<string>();

  useEffect(() => {
    setTimeout(() => {
      setDialog('DialogSendToOther');
    }, 1000);
  }, []);

  return (
    <DivisionAssetsProvider>
      <PageContainer>
        <Header />
        <ContentHeader>
          {!haveAllJointAssetsBeenAssigned && (
            <ResponsiveTextContainer marginTop={4} marginBottom={12}>
              <CardWarning>
                You need to divide all your shared assets before you can submit
                your offer.
              </CardWarning>
            </ResponsiveTextContainer>
          )}
          <Heading2>Submit</Heading2>

          {haveAllJointAssetsBeenAssigned && (
            <Paragraph>
              You suggest to divide your assets by doing the following:
            </Paragraph>
          )}

          {!haveAllJointAssetsBeenAssigned && (
            <Paragraph>
              You need to select what you will do with all of your shared assets
              before submitting.
            </Paragraph>
          )}
        </ContentHeader>

        <StyledPageContent>
          <ResponsiveTextContainer>
            <SelectedAssetsList />

            {!isSuperIncludedInOffer &&
              shortfallAmount > 0 &&
              isSuperSplittingSupported() && (
                <SpacedBox marginTop={12}>
                  <SuperannuationInfoMessage
                    isSuperIncludedInOffer={isSuperIncludedInOffer}
                    isOwingParty={isOwingParty}
                  />
                </SpacedBox>
              )}
          </ResponsiveTextContainer>
        </StyledPageContent>

        {canSubmitOffer && (
          <FixedFooter>
            <DialogSingleButton
              theme={blackTheme}
              showDialog={dialog === 'DialogSendToOther'}
              heading="Add a message to explain your proposal"
              prompt={`Let ${MatterProps(
                'other.firstname'
              )} know why you think your proposal is fair.`}
              onClick={() => navigate(ODRS_DIVISION_ASSETS_MESSAGING)}
              buttonLabel="Add message"
              buttonDisabled={!haveAllJointAssetsBeenAssigned}
              dataCy="add-message"
            />
          </FixedFooter>
        )}
      </PageContainer>
    </DivisionAssetsProvider>
  );
};

export default DivisionAssetsConfirm;
