import React from 'react';

import { H2Conversational, H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph, { Callout } from 'Common/UI/Text/Paragraph';
import { UList } from 'Common/UI/Text/List';
import HelpButton from 'Common/UI/Button/HelpButton';
import LearnMoreButton from 'Common/UI/Button/LearnMoreButton';
import { SlideInner, SlideText } from 'Common/UI/Carousel/ImageSlideWrapper';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';


const help = (
  <>
    <H1Conversational>Best interests: Factors to consider</H1Conversational>
    <Paragraph>
      Many considerations can be taken into account when working out a
      child&apos;s best interests. For example, when deciding parenting
      arrangements, you should consider:
    </Paragraph>

    <UList>
      <li>
        The safety of the child
      </li>
      <li>
        The child&apos;s age and their own views
      </li>
      <li>
        The development, psychological, emotional, and cultural needs of the child
      </li>
      <li>
        The capacity of each parent who has care of the child to provide for the 
        child&apos;s developmental, psychological, emotional, and cultural needs
      </li>
      <li>
        The benefit of the child having a relationship with either parent, and 
        other people who are significant to the child, where it is safe to do so
      </li>
      <li>
        Any other relevant circumstances of the child, i.e., medical and schooling
      </li>
    </UList>

    <Paragraph>
      For more information see the{' '}
      <ReferralLink href="https://www.amica.gov.au/parenting-arrangements.html">
        Parenting Arrangements
      </ReferralLink>{' '}
      page.
    </Paragraph>
  </>
);

const ParentingPlanSlide3 = () => (
  <SlideInner>
    <SlideText paddingTop data-cy="parent-slide-3">
      <H2Conversational>
        Working out your child&apos;s best interests
      </H2Conversational>
      <Callout>
        Every family&apos;s situation is different. What works for some might
        not work for others. When working out the best interests of your child,
        think about what has worked for your children.
      </Callout>

      <br />
      <br />
      <LearnMoreButton
        popUpContent={help}
        className="step-transition-fade-down"
        ui={HelpButton}
      >
        Best interests: Factors to consider
      </LearnMoreButton>
    </SlideText>
  </SlideInner>
);

export default ParentingPlanSlide3;
