import React from 'react';

import { PARTY_A } from 'Common/constants';
import formatCurrency from 'Common/Utils/formatCurrency';
import isNumeric from 'Common/Utils/isNumeric';
import isString from 'Common/Utils/isString';
import isDate from 'Common/Utils/isDate';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getUpdatedItemValues,
  getHighlightStatementValuesFunction,
  getWasUpdatedFunction,
  getRedactFunction,
  getFullName,
} from 'Common/Utils/statementHelpers';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';
import { formatDate } from 'Common/Data/App/helpers';

export const aboutYouLabel = 'About You';

export const aboutYouQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: ({ self: { firstname } }: Matter) => (
      <H1Conversational>
        Hi {firstname}, we’re going to ask you about your age, income and
        health.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division of your money and property on the information you give us.
      </Paragraph>
    ),
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'birthday',
    label: 'What is your date of birth?',
    hint: '',
    type: 'date',
    defaultValue: '',
    validate: value =>
      isDate(value) ? [true] : [false, 'Please enter in your date of birth'],
    index: 1,
    formAttributes: { max: new Date() },
    sensitive: true,
  },
  {
    name: 'b1',
    label: (
      <>
        Sorry. Because you’re under 18, you’re not able to use amica. Please
        visit the{' '}
        <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
          Help and Support
        </ReferralLink>{' '}
        page for other services that may be able to assist you.
      </>
    ),
    hint: '',
    type: 'blocker',
    defaultValue: '',
    index: 2,
    visible: answers => !isDate(answers.birthday, { atLeast: 18 }),
  },
  {
    name: 'paidwork',
    label: 'Do you currently have a job that you get paid for?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 3,
  },
  {
    name: 'job',
    label: 'What is your current occupation? ',
    hint: '',
    type: 'text',
    defaultValue: '',
    index: 4,
    visible: answers => !!answers.paidwork,
    validate: value =>
      isString(value)
        ? [true]
        : [false, 'Please tell us what your current occupation is'],
  },
  {
    name: 'earnings',
    label:
      'What was your total taxable income for the last financial year (a financial year is 1 July to 30 June)?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            This refers to your total taxable or &apos;gross&apos; income from
            paid work in the most recent year. It can be from more than one job,
            or source. For example it would include income from an investment
            property, but not any Centrelink benefits (we will cover these in a
            separate question).
          </Paragraph>
        </>
      ),
    },
    index: 5,
    validate: value =>
      isNumeric(value)
        ? [true]
        : [false, 'Please enter your earnings for the last financial year'],
  },
  {
    name: 'benefits',
    label:
      'Do you receive any pensions or other benefits from the Australian government (including Centrelink)?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            Benefits include any payments from government agencies in Australia,
            such as student support, low income support, parenting payments,
            allowances, veterans support or other payments. Do not include
            overseas pensions or payments.
          </Paragraph>
        </>
      ),
    },
    index: 6,
  },
  {
    name: 'benefitsamount',
    label:
      'How much money did you receive in benefits in the last financial year before it was taxed?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 7,
    visible: answers => !!answers.benefits,
    validate: value =>
      isNumeric(value)
        ? [true]
        : [
            false,
            'Please enter the pre-tax benefits that you received for the last financial year',
          ],
  },
  {
    name: 'healthissues',
    label: 'Do you have any health issues that affect your ability to work?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            This might be a physical disability, disease, chronic pain, an
            injury or a mental health issue. Work means both paid work outside
            the home and unpaid work to keep the home going, such as household
            duties or looking after the children. This is important because
            these factors can affect your future. amica takes your future needs
            into account when helping you and your former partner to divide your
            money and property.
          </Paragraph>
        </>
      ),
    },
    index: 8,
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 9,
  },
];

export const aboutYouStatement = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const party = data.CardIndex || matter.self.party;

  const partyData = party === PARTY_A ? matter.partyA : matter.partyB;

  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'birthday',
    'job',
    'paidwork',
    'earnings',
    'benefitsamount',
    'healthissues',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(aboutYouQuestions, data, hideSensitive);

  const name = getFullName(partyData, party, hideSensitive);

  const birthday = highlight`${name} was born ${updated(
    'birthday',
    redact('birthday', formatDate(data.birthday))
  )}.`;

  let job;

  if (data.paidwork) {
    job = highlight`They work as a ${updated(
      ['job', 'paidwork'],
      redact('job')
    )} and made ${updated(
      ['earnings', 'paidwork'],
      redact('earnings', formatCurrency(data.earnings))
    )} last year before tax.`;
  } else {
    job = highlight`They are ${updated(
      'paidwork',
      'not'
    )} currently in paid work.`;
  }

  let benefits;

  if (data.benefits) {
    benefits = highlight`They receive ${updated(
      'benefitsamount',
      redact('benefitsamount', formatCurrency(data.benefitsamount))
    )} through pensions or other benefits from the Australian government`;
  } else {
    benefits = highlight`They ${updated(
      'benefitsamount',
      'do not'
    )} receive any benefits.`;
  }

  let health;

  if (data.healthissues) {
    health = highlight`They ${updated(
      'healthissues',
      'do'
    )} have health issues that affect their ability to work.`;
  } else {
    health = highlight`They ${updated(
      'healthissues',
      'do not'
    )} have any health issues that affect their ability to work.`;
  }

  if (plainText) {
    return `${birthday} ${job} ${benefits} ${health}`;
  }

  return (
    <>
      {birthday} {job} {benefits} {health}
    </>
  );
};
