import type { Agreements } from 'Common/constants';
import { paymentsList } from './prices';
import { pricesUpdateOctober2024 } from './pricesUpdateOctober2024';

import type {
  AgreementPaymentType,
  AgreementPaymentTableType,
  UpdateAgreementPaymentTableType,
} from './types';

const paymentsListToTable = (
  plist: AgreementPaymentType[]
): AgreementPaymentTableType =>
  plist.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.type]: curr,
    }),
    {} as AgreementPaymentTableType
  );

const updatedPaymentList = (
  plist: AgreementPaymentType[],
  updates: UpdateAgreementPaymentTableType
): AgreementPaymentType[] => {
  const newPlist = plist.map(item => {
    if (updates[item.type]) {
      return { ...item, ...updates[item.type] };
    }
    return item;
  });
  return newPlist;
};

export default (matter: {
  created: string;
}): {
  list: AgreementPaymentType[];
  table: AgreementPaymentTableType;
} => {
  const paymentsListInitial = paymentsList();

  // Use updated prices for matters created on or after midnight, October 1 2024 (AEST)
  if (matter.created >= '2024-09-30T14:00:00.000Z') {
    const updatesForOctober2024 = updatedPaymentList(
      paymentsListInitial,
      pricesUpdateOctober2024()
    );
    return {
      list: updatesForOctober2024,
      table: paymentsListToTable(updatesForOctober2024),
    };
  }

  return {
    list: paymentsListInitial,
    table: paymentsListToTable(paymentsListInitial),
  };
};
