import {
  DOC_TYPE_PROPERTY_AGREEMENT,
  DOC_TYPE_PARENTING_AGREEMENT,
  DOC_TYPE_CONSENT_ORDERS,
} from 'Common/constants';
import type { AgreementPaymentType } from './types';

export const paymentsList = (): AgreementPaymentType[] => (
  [
    {
      type: DOC_TYPE_CONSENT_ORDERS,
      price: 275,
      gst: 25,
      priceExGST: 250,
      title: 'Application for consent orders',
      invoicetitle: 'Creation of consent orders',
      subtitle: 'Formal',
      description: [
        'You can apply to the courts to make your agreement legally binding in court orders. These are known as consent orders.',
        'You can use amica to create your consent order application documents to then lodge with the court.',
      ],
    },
    {
      type: DOC_TYPE_PARENTING_AGREEMENT,
      price: 165,
      gst: 15,
      priceExGST: 150,
      title: 'Parenting Agreement',
      subtitle: 'Informal',
      invoicetitle: 'Creation of parenting agreement',
      description: [
        "Creating a parenting agreement document gives you a written record of the parenting arrangements that you've agreed on amica. You will also have the option to convert your parenting agreement into a parenting plan which has more legal weight. We provide more info about this extra step in the document selection tab on your parenting dashboard.",
      ],
    },
    {
      type: DOC_TYPE_PROPERTY_AGREEMENT,
      price: 165,
      gst: 15,
      priceExGST: 150,
      title: 'Property Agreement',
      invoicetitle: 'Creation of property agreement',
      subtitle: 'Informal',
      description: [
        "Creating a property agreement allows you to create a written record of the agreement you've reached using amica.",
        'This will provide you both with an immediate written agreement which clearly sets out your intentions and records what you have agreed.',
      ],
    },
  ]
);


