import React from 'react';
import { ThemeProvider } from 'styled-components';

import { useAppSelector } from 'App/State/Store';
import IconConsentOrder from 'Common/Assets/images/icon-consent-order.svg';
import Paragraph from 'Common/UI/Text/Paragraph';
import HelpExternalButton from 'Common/UI/Button/HelpExternalButton';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import GenericButton from 'Common/UI/Button/GenericButton';
import { Label } from 'Common/UI/Text/Label';
import getNames from 'Common/Utils/getNames';
import {
  DocumentCardStatus,
  DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE,
  DOCUMENT_CARD_REQUIRED_SECTIONS_COMPLETE,
  DOCUMENT_CARD_COMPLETE,
  DOCUMENT_CARD_SELF_REQUESTED,
  DOCUMENT_CARD_OTHER_REQUESTED,
  DOCUMENT_CARD_BLOCKED,
  DOC_TYPE_CONSENT_ORDERS,
} from 'Common/constants';
import { getPricing } from 'Common/Utils/pricing';
import IconClock from 'Common/Assets/images/icon-clock.svg';
import TextButton from 'Common/UI/Button/TextButton';
import { Column } from 'Common/UI/Layout/Flex';
import { cardThemes, greenTheme } from 'Common/Utils/theme';
import colours from 'Common/Utils/colours';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import IconTextButton from 'Common/UI/Button/IconTextButton';
import { ODRS_CONSENT_ORDERS_INTRO_SLIDER_ROUTE } from 'Common/routes';
import IconArrowRight from 'Common/Assets/images/icon-arrow-right-white-small.svg';
import IconWarning from 'Common/Assets/images/icon-warning.svg';
import IconOther from 'Common/Assets/images/icon-users-other.svg';
import {
  CardWrapper,
  CardHeader,
  TickText,
  TickItem,
  CardContent,
  CardFooterWithDivider,
  CardFooterGrey,
  WaitingLabel,
} from '../common';

type Props = {
  isFree: boolean;
  isPaid: boolean;
  status: DocumentCardStatus;
  setPartyFlags: (flags: Record<string, boolean>) => Promise<void>;
  navigate: (route: string, state?: any) => void;
  consentOrdersOptIn: () => Promise<void>;
};

const ApplicationForConsentOrders: React.FC<Props> = ({
  isFree,
  isPaid,
  status = DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE,
  setPartyFlags,
  navigate,
  consentOrdersOptIn,
}) => {
  const matter = useAppSelector(state => state.matter);
  const { table: pricingTable } = getPricing(matter);

  const names = getNames();

  let price = `$${pricingTable[DOC_TYPE_CONSENT_ORDERS].priceExGST} +GST`;

  if (isFree) {
    price = 'Free';
  } else if (isPaid) {
    price = 'Paid';
  }

  const sharedContent = (
    <>
      <CardHeader
        icon={IconConsentOrder}
        subTitle="Court Application"
        price={price}
        title="Application for consent orders"
      />
      <CardContent>
        <Paragraph>
          You can apply to the Court to make orders confirming what you have
          agreed to do with your money and property.
        </Paragraph>
        <Paragraph>
          You can use amica to create your Court application documents.
        </Paragraph>
        <SpacedBox marginBottom={8}>
          <TickItem>
            <TickText>Create completed application documents</TickText>
          </TickItem>
          <TickItem>
            <TickText>
              File with the Court to make it legally enforceable once the Court
              makes orders
            </TickText>
          </TickItem>
        </SpacedBox>
        <HelpExternalButton href="https://www.amica.gov.au/info-about-separating/money-and-property">
          Learn more
        </HelpExternalButton>
      </CardContent>
    </>
  );

  switch (status) {
    case DOCUMENT_CARD_BLOCKED:
      return (
        <CardWrapper
          disabled
          data-cy="document-card-consent-orders"
          data-cy-status="blocked"
        >
          <CardHeader
            icon={IconConsentOrder}
            subTitle="Court Application"
            price={<img src={IconWarning} alt="" />}
            title="Application for consent orders"
          />
          <CardContent>
            <Paragraph>
              You can only make consent orders using amica for certain
              superannuation types. You have have chosen ‘other’. We recommend
              you seek legal help or visit our{' '}
              <ReferralLink href="https://amica.gov.au/info-about-separating/money-and-property">
                Money and Property
              </ReferralLink>{' '}
              page.
            </Paragraph>
          </CardContent>
        </CardWrapper>
      );
    case DOCUMENT_CARD_REQUIRED_SECTIONS_COMPLETE:
      return (
        <CardWrapper
          data-cy="document-card-consent-orders"
          data-cy-status="sections-complete"
        >
          {sharedContent}
          <CardFooterWithDivider>
            <ThemeProvider theme={greenTheme}>
              <GenericButton
                fullWidth
                onClick={() => setPartyFlags({ canStartConsentOrders: true })}
              >
                Create an application
              </GenericButton>
            </ThemeProvider>

            <SpacedBox marginTop={8}>
              <Label>
                {names.other} will also need to agree to creating an application
                for consent orders
              </Label>
            </SpacedBox>
          </CardFooterWithDivider>
        </CardWrapper>
      );
    case DOCUMENT_CARD_SELF_REQUESTED:
      return (
        <CardWrapper
          borderColour={colours.midPurple}
          data-cy="document-card-consent-orders"
          data-cy-status="self-requested"
        >
          {sharedContent}
          <CardFooterGrey>
            <WaitingLabel>
              <img src={IconClock} alt="" /> Waiting for {names.other} to agree
            </WaitingLabel>
            <GenericButton
              subtle
              small
              onClick={() => setPartyFlags({ canStartConsentOrders: false })}
            >
              Undo
            </GenericButton>
          </CardFooterGrey>
        </CardWrapper>
      );
    case DOCUMENT_CARD_OTHER_REQUESTED:
      return (
        <CardWrapper
          borderColour={colours.midTeal}
          data-cy="document-card-consent-orders"
          data-cy-status="other-requested"
        >
          {sharedContent}
          <CardFooterGrey>
            <WaitingLabel>
              <img src={IconOther} alt="" /> {names.other} has requested to
              create an application for consent orders
            </WaitingLabel>
            <Column>
              <ThemeProvider theme={cardThemes.other}>
                <GenericButton
                  fullWidth
                  onClick={async () => {
                    await setPartyFlags({ canStartConsentOrders: true });
                    await consentOrdersOptIn();
                  }}
                >
                  Agree to create an application
                </GenericButton>
                <TextButton
                  fullWidth
                  onClick={() =>
                    setPartyFlags({ notReadyToStartConsentOrders: true })
                  }
                >
                  No thanks
                </TextButton>
              </ThemeProvider>
            </Column>
          </CardFooterGrey>
        </CardWrapper>
      );
    case DOCUMENT_CARD_COMPLETE:
      return (
        <CardWrapper
          data-cy="document-card-consent-orders"
          data-cy-status="complete"
        >
          {sharedContent}
          <CardFooterWithDivider>
            <ThemeProvider theme={greenTheme}>
              <IconTextButton
                fullWidth
                icon={IconArrowRight}
                onClick={() => navigate(ODRS_CONSENT_ORDERS_INTRO_SLIDER_ROUTE)}
              >
                Go to section
              </IconTextButton>
            </ThemeProvider>

            <SpacedBox marginTop={8}>
              <Label>
                The application for consent orders requires further information
              </Label>
            </SpacedBox>
          </CardFooterWithDivider>
        </CardWrapper>
      );
    case DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE:
    default:
      return (
        <CardWrapper
          data-cy="document-card-consent-orders"
          data-cy-status="sections-incomplete"
        >
          {sharedContent}
        </CardWrapper>
      );
  }
};

export default ApplicationForConsentOrders;
