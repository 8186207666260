import {
  DOC_TYPE_PROPERTY_AGREEMENT,
  DOC_TYPE_PARENTING_AGREEMENT,
  DOC_TYPE_CONSENT_ORDERS,
} from 'Common/constants';

import type { UpdateAgreementPaymentTableType } from './types';

export const pricesUpdateOctober2024 = (): UpdateAgreementPaymentTableType => ({
  [DOC_TYPE_CONSENT_ORDERS]: {
    price: 990,
    gst: 90,
    priceExGST: 900,
  },
  [DOC_TYPE_PARENTING_AGREEMENT]: {
    price: 297,
    gst: 27,
    priceExGST: 270,
  },
  [DOC_TYPE_PROPERTY_AGREEMENT]: {
    price: 297,
    gst: 27,
    priceExGST: 270,
  },
});
