import React, { ReactNode, useContext } from 'react';
import styled from 'styled-components';

import FlexGrid from 'Common/UI/Layout/FlexGrid';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import AddButton from 'Common/UI/Button/AddButton';
import {
  CardAny,
  DataGroup,
  DataPage,
  DataGroupRepeatable,
  QuestionStateCard,
  EphemeralStateCard,
  QuestionCard,
  EphemeralCard,
} from 'Common/Data/Types/appSections';
import { Theme } from 'Common/Utils/theme';
import Matter from 'Common/Data/Types/matter';
import {
  PARTY_BOTH,
  CARD_NORMAL,
  CARD_YOUR_SUPERANNUATION,
} from 'Common/constants';
import { Row } from 'Common/UI/Layout/Flex';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import { filterPrivateCards } from 'Common/Utils/dashboardHelpers';
import { DashboardContext } from 'Common/UI/DashboardContext';
import generateCardOptionsData from 'Common/UI/Dashboard/common/generateCardOptionsData';
import {
  canAddNewCardsOnAppState,
  GroupDescription,
  GroupHeading,
  GroupWrap,
  PageDescription,
} from 'Common/UI/Dashboard/common';
import { EmptyOptionsCard } from 'Common/UI/Dashboard/common/EmptyOptionsCard';
import generateStateCardData from 'Common/UI/Dashboard/common/generateStateCardData';

const GroupIcon = styled.img<{
  theme: Theme;
}>`
  display: block;
  margin: ${({ theme }) => theme.padding.small}px 0;
`;

type Props = {
  pageData?: DataPage;
  navigate: (route: string, state?: any) => void;
  matter: Matter;
  cardHandlers: {
    [key: string]: (
      card: QuestionStateCard<QuestionCard> | EphemeralStateCard<EphemeralCard>
    ) => ReactNode;
  };
  appState: string;
  handleAddItemById?: (group: DataGroup) => Promise<any>;
};

const isGroupRepeatable = (group: DataGroup): group is DataGroupRepeatable =>
  (group as DataGroupRepeatable).isRepeatable;

const HeadingButton: React.FC<{
  group: DataGroup;
  handleAddItemById: (group: DataGroup) => void;
}> = ({ group, handleAddItemById }) => (
  <AddButton
    data-cy={`add-card-button-${group.id}`}
    onClick={() => handleAddItemById(group)}
  />
);

const DashboardContent: React.FC<Props> = ({
  pageData,
  navigate,
  matter,
  cardHandlers,
  appState,
  handleAddItemById,
}) => {
  const { showOptionsDrawer, section, tab } = useContext(DashboardContext);

  if (!pageData) {
    throw new Error('Error loading page data');
  }

  const {
    subTitle,
    description,
    children,
    infoContent,
    generateStatus,
    getPageButtonProps,
  } = pageData;

  const isPageUnlocked = generateStatus(matter) !== 'locked';
  const isSuggestedDivisionFlowStarted =
    matter.self.hasConfirmedStatements || matter.other.hasConfirmedStatements;

  return (
    <>
      {(subTitle || description) && (
        <PageDescription
          infoContent={infoContent}
          subTitle={subTitle}
          description={description}
          getPageButtonProps={getPageButtonProps}
        />
      )}
      {children.map((group: DataGroup) => {
        let shouldShowEmptyOptionsCard = false;

        const numCardsForSelf = group.children.filter((item: CardAny) => {
          if ('cardForParty' in item) {
            return (
              [CARD_NORMAL, CARD_YOUR_SUPERANNUATION].includes(item.type) &&
              (item.cardForParty === 'self' || item.cardForParty === PARTY_BOTH)
            );
          }

          return true;
        }).length;

        if (isGroupRepeatable(group)) {
          if (group.isIndividual) {
            shouldShowEmptyOptionsCard = numCardsForSelf === 0;
          } else {
            shouldShowEmptyOptionsCard = group.children.length === 0;
          }

          if (
            shouldShowEmptyOptionsCard &&
            group.promptCardData.visibilityCheck
          ) {
            shouldShowEmptyOptionsCard =
              group.promptCardData.visibilityCheck(matter);
          }
        }
        if (group.hideOnDashboard) {
          return null;
        }

        return (
          <GroupWrap
            key={group.id}
            slim={group.slim}
            noMinHeight={
              !Array.isArray(group.children) || group.children.length === 0
            }
          >
            {group.icon && <GroupIcon src={group.icon} alt="" />}
            <SpacedBox marginBottom={6}>
              <ResponsiveContainer>
                <Row justifyContent="space-between">
                  {group.title && (
                    <GroupHeading infoContent={infoContent}>
                      {group.title}
                    </GroupHeading>
                  )}
                  {isGroupRepeatable(group) &&
                    numCardsForSelf > 0 &&
                    handleAddItemById &&
                    canAddNewCardsOnAppState(appState) &&
                    !isSuggestedDivisionFlowStarted && (
                      <HeadingButton
                        group={group}
                        handleAddItemById={handleAddItemById}
                      />
                    )}
                </Row>
              </ResponsiveContainer>
            </SpacedBox>
            {group.description && (
              <ResponsiveTextContainer marginBottom={10}>
                <GroupDescription>{group.description}</GroupDescription>
              </ResponsiveTextContainer>
            )}
            <FlexGrid medium={2} large={2}>
              {shouldShowEmptyOptionsCard ? (
                <EmptyOptionsCard
                  group={group as DataGroupRepeatable}
                  active={isPageUnlocked}
                />
              ) : (
                group.children
                  .filter(filterPrivateCards)
                  .map(card =>
                    generateStateCardData({
                      card,
                      matter,
                      navigate,
                      cardOptions: generateCardOptionsData(
                        card,
                        matter,
                        section,
                        tab,
                        showOptionsDrawer
                      ),
                    })
                  )
                  .map(data => {
                    const generateCard = cardHandlers[data.type];
                    const defaultCard = () => null;

                    // don't show card on dashboard
                    if (data.hideOnDashboard) return null;

                    return generateCard ? generateCard(data) : defaultCard();
                  })
              )}
            </FlexGrid>
          </GroupWrap>
        );
      })}
    </>
  );
};

export default DashboardContent;
