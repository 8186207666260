import React from 'react';
import moment, { MomentInput } from 'moment';

import {
  DATE_FORMAT_LONG,
  DEFAULT_OTHER_TEXT,
  PARTY_A,
  PARTY_B,
  Party,
} from 'Common/constants';

import capitaliseText from 'Common/Utils/capitaliseText';
import MatterPropsJS from 'Common/Utils/MatterProps';

import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational, H2Conversational } from 'Common/UI/Text/Headings';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';

import isDate from 'Common/Utils/isDate';
import getOtherParty from 'Common/Utils/getOtherParty';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import Matter from 'Common/Data/Types/matter';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';

export const relationshipLabel = 'Relationship';

export const relationshipQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: ({ self: { firstname } }: Matter) => (
      <H1Conversational>
        Hi {firstname}, we’re going to ask you about your relationship.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division of your money and property on the information you give us.
      </Paragraph>
    ),
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'uniondate',
    label: () =>
      `When did you and ${MatterPropsJS(
        'other.firstname',
        DEFAULT_OTHER_TEXT
      )} start living together?`,
    hint: '',
    type: 'date',
    defaultValue: '',
    index: 1,
    validate: value =>
      isDate(value) && !isDate(value, { inPast: true })
        ? [true]
        : [false, 'Please enter a valid date'],
    formAttributes: { max: new Date() },
    help: {
      label: 'Why are you asking?',
      content: (
        <>
          <H1Conversational>Why are you asking?</H1Conversational>
          <Paragraph large>
            This is important because how long you lived together may affect how
            you will share your money and property. It is okay if you do not
            know the exact date but you will need to have the month and year.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'separationdate',
    label: () =>
      `What date did you and ${MatterPropsJS('other.firstname')} separate?`,
    hint: '',
    type: 'date',
    defaultValue: '',
    index: 2,
    validate: (value, { uniondate } = {}) =>
      isDate(value, { after: uniondate as MomentInput })
        ? [true]
        : [
            false,
            `Please enter a valid date after ${
              moment(uniondate as MomentInput).isValid()
                ? moment(uniondate as MomentInput).format(DATE_FORMAT_LONG)
                : ''
            }`,
          ],
    formAttributes: { max: new Date() },
    help: {
      label: 'What counts as a date of separation?',
      content: (
        <>
          <H1Conversational>
            What counts as a date of separation?
          </H1Conversational>
          <Paragraph large>
            This might be the date you or your former partner moved out, the
            date you told your friends and family you had broken up or any other
            date you and your former partner agree on. In some instances, you
            can be separated and still living under the same roof. If you are
            unsure whether you are seperated, see the{' '}
            <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
              Help and Support page
            </ReferralLink>
            .
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'info1',
    label:
      "If you've been separated for more than 2 years and were not married, you will need to apply for an extension when you file your consent order. At the moment, amica cannot help you with this so you will need to seek legal advice. If you were married, you can still continue.",
    hint: '',
    type: 'info',
    defaultValue: '',
    index: 3,
    visible: answers => isDate(answers.separationdate, { atLeast: 2 }),
  },
  {
    name: 'married',
    label: 'Were you married?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 4,
  },
  {
    name: 'marriedDate',
    label: 'What date did you get married?',
    hint: '',
    type: 'date',
    visible: answers => !!answers.married,
    defaultValue: '',
    index: 5,
    formAttributes: { max: new Date() },
  },
  {
    name: 'marriedWhere',
    label: 'Where were you married?',
    hint: '',
    type: 'country_city',
    visible: answers => !!answers.married,
    defaultValue: '',
    index: 6,
  },
  {
    name: 'divorced',
    label: 'Okay. And have you divorced?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 7,
    visible: answers => !!answers.married,
    help: {
      label: 'Why is this important?',
      content: (
        <>
          <H1Conversational>Why is this important?</H1Conversational>
          <Paragraph large>
            You can organise your property settlement regardless of whether you
            have divorced or not.
          </Paragraph>

          <Paragraph large>
            <strong>
              Important: Once your divorce is finalised, you have 12 months
              within which you are eligible to apply to the Federal circuit and
              Family Court of Australia about the division of money and
              property.
            </strong>
          </Paragraph>

          <Paragraph large>
            If you live in Western Australia, you can apply to the Family Court
            of WA to resolve your property settlement.
          </Paragraph>

          <Paragraph large>
            If you do not apply within 12 months, you will need permission from
            the court to apply for a property settlement. Permission is not
            always granted.
          </Paragraph>

          <H2Conversational>
            Using amica when you are already divorced
          </H2Conversational>

          <Paragraph large>
            If you are already divorced, you should only use amica if:
          </Paragraph>

          <ul>
            <li>
              there are at least 3 months remaining on the 12 month time limit
              mentioned above, and
            </li>
            <li>
              you are confident that you and your ex-partner can use amica to
              fairly divide your money and property before the 12 month time
              limit expires.
            </li>
          </ul>

          <Paragraph large>
            Still not sure? See the{' '}
            <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
              Help and Support page
            </ReferralLink>
            .
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'divorcedWhere',
    label: 'Where did you finalise your divorce?',
    hint: '',
    type: 'country_city',
    visible: answers => !!answers.divorced,
    defaultValue: '',
    index: 8,
  },
  {
    name: 'divorcedate',
    label: () =>
      `When did you and ${MatterPropsJS(
        'other.firstname'
      )} finalise your divorce?`,
    hint: '',
    type: 'date',
    defaultValue: '',
    index: 9,
    visible: answers => !!answers.divorced,
    validate: (value, { uniondate } = {}) =>
      isDate(value, { after: uniondate as MomentInput })
        ? [true]
        : [
            false,
            `Please enter a valid date after ${
              moment(uniondate as MomentInput).isValid()
                ? moment(uniondate as MomentInput).format(DATE_FORMAT_LONG)
                : ''
            }`,
          ],
    formAttributes: { max: new Date() },
  },
  {
    name: 'b1',
    label:
      'As you divorced more than 9 months ago, you will likely need to apply for an extension before you can file your consent order. At the moment, amica cannot help you with this so you will need to seek legal advice.',
    hint: '',
    type: 'blocker',
    defaultValue: '',
    index: 10,
    visible: answers => isDate(answers.divorcedate, { atLeastMonths: 9 }),
  },
  {
    name: 'haveChildren',
    label: () =>
      `Do you and ${MatterPropsJS(
        'other.firstname'
      )} have any children under 18?`,
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 11,
    help: {
      label: 'Why are you asking this?',
      content: (
        <>
          <H1Conversational>Why are you asking this?</H1Conversational>

          <Paragraph large>
            If you have children under 18, your share of the parental
            responsibility and any child support payments will affect how your
            money and property are divided. You will be able to fill in more
            information about children in other sections.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'childcareResponsibilities',
    label: 'How do you plan to share parenting responsibilities?',
    hint: '',
    type: 'select',
    options: _MatterPropsJS => [
      {
        label: "I'll be sole carer",
        value: _MatterPropsJS('self.party'),
      },
      {
        label: "I'll have more childcare responsibility",
        value: `mostly${_MatterPropsJS('self.party')}`,
      },
      {
        label: "We'll share equally",
        value: 'both',
      },
      {
        label: `${capitaliseText(
          _MatterPropsJS('other.firstname', DEFAULT_OTHER_TEXT)
        )} will have more childcare responsibility`,
        value: `mostly${_MatterPropsJS('other.party')}`,
      },
      {
        label: `${capitaliseText(
          _MatterPropsJS('other.firstname', DEFAULT_OTHER_TEXT)
        )} will be sole carer`,
        value: _MatterPropsJS('other.party', PARTY_A),
      },
    ],
    defaultValue: '',
    visible: answers => !!answers.haveChildren,
    index: 12,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            The primary carer is the person that has the most responsibility for
            looking after the child. They will usually live with this person
            more. If you are unsure about how much child care responsibility you
            are going to have, see the{' '}
            <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
              Help and Support page
            </ReferralLink>
            .
          </Paragraph>
          <Paragraph large>
            You will get to work out parenting arrangements in detail later. At
            this stage, we are gathering an overview of your situation.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'childSupport',
    label: 'Do either of you pay child support?',
    hint: '',
    type: 'select',
    options: _MatterPropsJS => [
      {
        label: 'I pay',
        value: _MatterPropsJS('self.party'),
      },
      {
        label: `${_MatterPropsJS(
          'other.firstname',
          'Your former partner'
        )} pays`,
        value: _MatterPropsJS('other.party'),
      },
      {
        label: 'Neither of us pays',
        value: 'none',
      },
    ],
    defaultValue: '',
    visible: answers => !!answers.haveChildren,
    index: 13,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            By law, both parents are required to provide financial support for
            their children following separation. The amount of Child Support
            that needs to be paid depends on your circumstances. If you are
            unsure if you should be seeking or paying Child Support, see the{' '}
            <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
              Help and Support page
            </ReferralLink>
            .
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 14,
  },
];

export const relationshipStatement = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'uniondate',
    'separationdate',
    'marriedDate',
    'divorcedate',
    'marriedWhere',
    'divorcedWhere',
    'married',
    'haveChildren',
    'childSupport',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(relationshipQuestions, data, hideSensitive);

  const partyNames = {
    A: getFirstName(matter.partyA, PARTY_A, hideSensitive),
    B: getFirstName(matter.partyB, PARTY_B, hideSensitive),
  };

  const relationshipPeriod = highlight`${partyNames.A} and ${
    partyNames.B
  } started living together on ${updated(
    'uniondate',
    redact('uniondate', moment(data.uniondate).format(DATE_FORMAT_LONG))
  )} 
    and separated on ${updated(
      'separationdate',
      moment(data.separationdate).format(DATE_FORMAT_LONG)
    )}.`;

  let married;

  const getCountryCity = (key: any) => [
    updated(key, redact(key, data[key].country)),
    updated(key, redact(key, data[key].city)),
  ];

  const hasMarried = data.married && data.marriedDate && data.marriedWhere;
  const hasDivorced = data.divorced && data.divorcedate && data.divorcedWhere;
  if (hasMarried) {
    const [marriedCountry, marriedCity] = getCountryCity('marriedWhere');
    const marriedDate = updated(
      'marriedDate',
      redact('marriedDate', moment(data.marriedDate).format(DATE_FORMAT_LONG))
    );
    if (hasDivorced) {
      const [divorcedCountry, divorcedCity] = getCountryCity('divorcedWhere');
      const divorcedDate = updated(
        'divorcedate',
        redact('divorcedate', moment(data.divorcedate).format(DATE_FORMAT_LONG))
      );
      married = highlight`They married on ${marriedDate} in ${marriedCity}, ${
        marriedCountry || 'Australia'
      }. They finalised their divorce on ${divorcedDate} in ${divorcedCity}, ${
        divorcedCountry || 'Australia'
      }.`;
    } else {
      married = highlight`They married on ${marriedDate} in ${marriedCity}, ${
        marriedCountry || 'Australia'
      }. They have not finalised their divorce.`;
    }
  } else {
    married = highlight`They are ${updated('married', 'not')} married.`;
  }

  let children;
  let carer;
  let childSupport;

  if (data.haveChildren > 0) {
    children = highlight`They ${updated(
      'haveChildren',
      'do'
    )} have children together under 18.`;

    if (
      data.childcareResponsibilities === 'A' ||
      data.childcareResponsibilities === 'B'
    ) {
      carer = highlight`${partyNames[data.childcareResponsibilities as Party]}
        will care for the children ${'100%'} of the time and 
        ${partyNames[getOtherParty(data.childcareResponsibilities)]} 
        will care for the children ${'0%'}.`;
    } else if (
      data.childcareResponsibilities === 'mostlyA' ||
      data.childcareResponsibilities === 'mostlyB'
    ) {
      const mostlyParty = data.childcareResponsibilities.slice(
        data.childcareResponsibilities.length - 1
      );

      carer = highlight`${partyNames[mostlyParty as Party]}
        will care for the children ${'75%'} of the time and 
        ${partyNames[getOtherParty(mostlyParty)]} 
        will care for the children ${'25%'}.`;
    } else {
      carer = highlight`${partyNames.A}
        will care for the children ${'50%'} of the time and 
        ${partyNames.B} 
        will care for the children ${'50%'}.`;
    }

    if (data.childSupport === 'A' || data.childSupport === 'B') {
      childSupport = highlight`${updated(
        'childSupport',
        partyNames[data.childSupport as Party]
      )}
        is currently paying child support.`;
    } else {
      childSupport = highlight`${updated(
        'childSupport',
        'Neither person'
      )} is currently paying child support.`;
    }
  } else {
    children = highlight`They ${updated(
      'haveChildren',
      "don't"
    )} have any children together under 18.`;
  }

  if (plainText) {
    return `${relationshipPeriod} ${married} ${children} ${carer} ${childSupport}`;
  }

  return (
    <>
      {relationshipPeriod} {married} {children} {carer} {childSupport}
    </>
  );
};
